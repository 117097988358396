<template>
  <section class="bg-light h-100 p-3">
    <h2>Liste des postulants Mk-co</h2>
    <hr />
    <div>
      <table class="table table-hover table-responsive">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Nom &amp; prénom</th>
            <th scope="col">Email</th>
            <th scope="col">Téléphone</th>
            <th scope="col" class="text-center">Status</th>
            <th scope="col" class="text-center">Date</th>
            <th scope="col" class="text-center">Pieces jointes & Message</th>
            <th scope="col" class="text-center" colspan="2">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            :class="isUntreated(item.status_candidat_mk_co)"
            v-for="item in pageOfItems"
            :key="item.id_candidat_mk_co"
          >
            <th scope="row">{{ item.id_candidat_mk_co }}</th>
            <td>{{ item.full_name_candidat_mk_co }}</td>
            <td>{{ item.email_candidat_mk_co }}</td>
            <td>{{ item.tel_candidat_mk_co }}</td>
            <td class="text-center">
              <span
                class="badge rounded-pill"
                :class="badgeStatus(item.status_candidat_mk_co)"
                >{{ showStatus(item.status_candidat_mk_co) }}
              </span>
            </td>
            <td class="text-center">
              {{
                moment(item.date).calendar(null, {
                  sameWeek: "ddd",
                  sameElse: "DD MMMM YYYY",
                  sameDay: "[Aujourd'hui]",
                  nextDay: "[Demain]",
                  lastWeek: "dddd [dernier]",
                  nextWeek: "dddd",
                  lastDay: "[Hier]",
                })
              }}
            </td>
            <td class="text-center">
              <a
                class="mx-2"
                :href="`${url_pdf_mk_co}${item.url_files_candidat_mk_co}`"
                target="blank"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Télécharger"
              >
                <IcoFile
              /></a>
              <IcoMessageTextOutline
                class="icon"
                data-bs-toggle="modal"
                data-bs-target="#modalMessage"
                @click="setMessage(item.message_candidat_mk_co)"
              />
            </td>
            <td class="text-center">
              <div
                class="dropdown"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Gérer"
              >
                <div data-bs-toggle="dropdown" aria-expanded="false">
                  <IcoUpdate class="icon" />
                </div>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
                  <li v-if="item.status_candidat_mk_co !== 'ACCEPT'">
                    <button
                      class="dropdown-item"
                      type="button"
                      @click="updateStatus(item.id_candidat_mk_co, 'ACCEPT')"
                    >
                      Accepter
                    </button>
                  </li>
                  <li v-if="item.status_candidat_mk_co !== 'DECLINE'">
                    <button
                      class="dropdown-item"
                      type="button"
                      @click="updateStatus(item.id_candidat_mk_co, 'DECLINE')"
                    >
                      Refuser
                    </button>
                  </li>
                  <li v-if="item.status_candidat_mk_co !== 'POTENTIAL'">
                    <button
                      class="dropdown-item"
                      type="button"
                      @click="updateStatus(item.id_candidat_mk_co, 'POTENTIAL')"
                    >
                      Potentielle
                    </button>
                  </li>
                  <!-- <li v-if="item.status_candidat_mk_co !== 'UNTREATED'">
                    <button
                      class="dropdown-item"
                      type="button"
                      @click="updateStatus(item.id_candidat_mk_co, 'UNTREATED')"
                    >
                      Non Traité
                    </button>
                  </li> -->
                  <li v-if="item.status_candidat_mk_co !== 'POSTE'">
                    <button
                      class="dropdown-item"
                      type="button"
                      @click="updateStatus(item.id_candidat_mk_co, 'POSTE')"
                    >
                      En Poste
                    </button>
                  </li>
                  <li v-if="item.status_candidat_mk_co !== 'OLD'">
                    <button
                      class="dropdown-item"
                      type="button"
                      @click="updateStatus(item.id_candidat_mk_co, 'OLD')"
                    >
                      Ancien
                    </button>
                  </li>
                </ul>
              </div>
            </td>
            <td class="text-center">
              <div
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Supprimer"
              >
                <IcoDelete
                  class="icon"
                  data-bs-toggle="modal"
                  data-bs-target="#modalDelete"
                  @click="initDelete(item.id_candidat_mk_co)"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- modal delete début-->
    <div
      class="modal fade"
      id="modalDelete"
      tabindex="-1"
      aria-labelledby="modalLabelDelete"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content border-0 bg-light text-dark">
          <div class="modal-header mx-2">
            <h4 class="modal-title text-primary" id="modalLabelDelete">
              Supprimer la candidature
            </h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <section class="row p-2">
              <div class="col-12">
                Les informations de cette candidature ne seront plus disponible
              </div>
            </section>
          </div>
          <div class="modal-footer mx-2">
            <button
              type="button"
              class="btn btn-secondary me-0"
              data-bs-dismiss="modal"
              ref="CloseDelete"
            >
              Annuler
            </button>
            <div
              v-if="loading.delete"
              class="mx-5 spinner-border text-warning"
              role="status"
            ></div>
            <button
              v-else
              @click="deleteCandidat()"
              class="btn btn-primary ms-3"
            >
              Supprimer
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- modal message début-->
    <div
      class="modal fade"
      id="modalMessage"
      tabindex="-1"
      aria-labelledby="modalLabelMessage"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content border-0 bg-light text-dark">
          <div class="modal-header mx-2">
            <h4 class="modal-title text-primary" id="modalLabelMessage">
              Message du condidat
            </h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <section class="row p-2">
              <div class="col-12">
                {{ message }}
              </div>
            </section>
          </div>
          <div class="modal-footer mx-2">
            <button
              type="button"
              class="btn btn-secondary me-0"
              data-bs-dismiss="modal"
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal message fin-->

    <!-- modal delete fin-->
    <div class="d-flex justify-content-center">
      <CustomPagination
        :pageSize="5"
        :items.sync="initialData"
        @changePage="onChangePage"
      ></CustomPagination>
    </div>
  </section>
</template>

<script>
import IcoFile from "vue-material-design-icons/FileAccount.vue";
import IcoUpdate from "vue-material-design-icons/DotsGrid.vue";
import IcoDelete from "vue-material-design-icons/Delete.vue";
import { URL_PDF_MK } from "../../configs/index";
import { success, error } from "../../utils/toast";
import {
  deleteCandidatMk,
  getAllCandidatMk,
  editCandidatMkById,
} from "../../api/candidatMk";
import { socket } from "../../api/socket";
import CustomPagination from "../../components/public/Pagination.vue";
import { showStatus, badgeStatus } from "../../utils/index";
import IcoMessageTextOutline from "vue-material-design-icons/MessageTextOutline.vue";

export default {
  name: "CandidatMk",
  components: {
    IcoFile,
    IcoUpdate,
    IcoDelete,
    CustomPagination,
    IcoMessageTextOutline,
  },
  data() {
    return {
      url_pdf_mk_co: URL_PDF_MK,
      data: [],
      idDelete: 0,
      message: "",
      pageOfItems: [],
      initialData: [],
      loading: {
        data: false,
        update: false,
        delete: false,
      },
    };
  },
  mounted() {
    this.fetch();
    socket.on("arrival-notif", () => {
      this.fetch();
    });
  },
  methods: {
    fetch() {
      getAllCandidatMk().then((result) => {
        this.loading.data = false;
        this.initialData = result.data;
      });
    },
    initDelete(id) {
      this.idDelete = id;
    },
    showStatus(text) {
      return showStatus(text);
    },
    badgeStatus(text) {
      return badgeStatus(text);
    },
    isUntreated(text) {
      if (text == "UNTREATED") {
        return "fw-bolder";
      } else {
        return "";
      }
    },
    updateStatus(id, status) {
      editCandidatMkById(id, status).then((result) => {
        if (result.data.error) {
          error(result.data.error, "");
        } else {
          success("Status candidat modifié");
          socket.emit("send-view");
          this.fetch();
        }
      });
    },
    deleteCandidat() {
      this.loading.delete = true;
      deleteCandidatMk(this.idDelete).then(() => {
        this.fetch();
        this.loading.delete = false;
        this.$refs.CloseDelete.click();
        socket.emit("send-view");
        success("Candidature supprimée");
      });
    },
    onChangePage(pageOfItems) {
      window.scrollTo(0, 0);
      this.pageOfItems = pageOfItems;
    },
    setMessage(message) {
      if (message.length) {
        this.message = message;
      } else {
        this.message = "Le candidat n'a pas envoyé de message.";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  cursor: pointer;
}
</style>
